import React from 'react';

function App() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Hello, World!</h1>
      <p>Welcome to your first React app!</p>
    </div>
  );
}

export default App;
